<template>
  <div>
    <v-card max-width="400" class="mt-8">
      <v-img
        class="white--text align-end"
        height="200px"
        src="~@/assets/icons/houses.png"
      >
      </v-img>

      <v-card-text class="text--primary">
        <div>Диктующие точки.</div>
      </v-card-text>

      <v-card-actions>
        <v-btn color="secondary" text @click="$router.push({ name: 'points' })">
          Список
        </v-btn>

        <v-btn
          color="secondary"
          text
          @click="$router.push({ name: 'PointAddress' })"
        >
          Создать
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
